import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

const siteTitleQuery = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`

const Header = () => {
  const data = useStaticQuery(siteTitleQuery)

  return (
    <header className="global-header">
      <div>
        <h1 className="site-title">
          <Link to="/">{data.site.siteMetadata.title}</Link>
        </h1>
        <h3 className="site-subtitle">Software Engineer</h3>
      </div>
      <nav>
        <Link to="/">Portfolio</Link>
        <Link to="/blog">Blog</Link>
        <a href="https://github.com/chris-muller" target="_blank" rel="noopener noreferrer">
          Github
        </a>
      </nav>
    </header>
  )
}

export default Header
